let opacity = 1;

export const { document: doc } = window;

/**
 * @param {DOM Element} parent element of the target
 * @param {String} target identifier of the selector
 * @description Returns a DOM node
 */
export function getNode (parent, target) {
    return parent.querySelector(target);
}

export function showEl (el) {
    el.classList.remove('hide');
    el.classList.add('show');
}

export function hideEl (el) {
    el.classList.add('hide');
    el.classList.remove('show');
}

export function active (el) {
    el.classList.add('active');
}

export function inactive (el) {
    el.classList.remove('active');
}

/**
 * @param {DOM Element} parentNode
 * @param {DOM Element} node
 * @description Fades the target node using opacity and removes it from the DOM after
 */
export function fadeNode(parentNode, node) {
    if (opacity <= 1 && opacity > 0) {
        opacity -= .1;
        setTimeout(() => fadeNode(parentNode, node), 300);
    } else {
        // Remove after fade
        parentNode.removeChild(node);
    }

    node.style.opacity = opacity;
}
