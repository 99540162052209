import displayMessage from './display-message';
import { doc, getNode, fadeNode } from './utils';
import { observer } from './lazy-load-images';

const tiles = doc.querySelectorAll('.tile');
const contactUs = doc.querySelector('.contact-us');
const signup = doc.querySelector('.submit-email');

const prevButton = doc.querySelectorAll('.prev');
const nextButton = doc.querySelectorAll('.next');

// Wire search and filter on homepage
if (tiles.length) {
    import('./search').then(({ default: search }) => search(tiles));
    import('./filter').then(({ default: filter }) => filter(tiles));
}

doc.querySelectorAll('.lazy').forEach(img => observer.observe(img));

// Wire contact popup
contactUs.addEventListener('click', e => {
    e.preventDefault();

    const contactPopUp = doc.querySelector('.contact-us-popup');

    if (contactPopUp) {
        // Show form if the element has been created
        contactPopUp.classList.add('show');
    } else {
        // Create the element if it doesn't exist yet
        import('./contact-us')
            .then(({ default: contactPopup }) => contactPopup)
            .then(contactModal => contactModal())
            .catch(err => console.error('Could not create contact form :: ', err))

    }
});

// Toast mock up
async function displaySuccessToast () {
    console.log('success');
    // await import('./toast.html');
}

// Wire email sign up in footer
signup.addEventListener('click', e => {
    e.preventDefault();

    const signUpInput = doc.querySelector('.sign-up');
    const body = {
        email: signUpInput.value,
        date: new Date(),
        optin: true
    }

    fetch('/email-sign-up', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(res => res.json())
    .then(data => {
        if (data.success) {
            const parentNode = getNode(doc.querySelector('.footer'), '.sign-up');
            const msgNode = displayMessage(`
                Success! You'll receive exclusive updates and deals.
            `);

            parentNode.appendChild(msgNode);

            const messageNode = getNode(parentNode, '.display-message');
            fadeNode(parentNode, messageNode);

            parentNode.value = '';
        }
    });
})

prevButton.forEach(b => b.addEventListener('click', e => {
        const thumbsContainer = e.target.parentElement.querySelector('.thumbs');
        const children = thumbsContainer.children;
        const cLength = children.length;
        let i;

        for (i = 0; i < cLength; i ++) {
            const thumb = children[i];

            if (thumb.classList.contains('show')) {
                let number = parseInt(thumb.dataset.number);

                for (i = 0; i < cLength; i++) {
                    const hideThumb = children[i];

                    hideThumb.classList.add('hide');
                    hideThumb.classList.remove('show');
                }

                if (number + 1 === cLength) {
                    children[0].classList.add('show');
                    children[0].classList.remove('hide');
                } else {
                    children[number + 1].classList.add('show');
                    children[number + 1].classList.remove('hide')
                }
            }
        }
    })
);

nextButton.forEach(b => b.addEventListener('click', e => {
        const thumbsContainer = e.target.parentElement.querySelector('.thumbs');
        const children = thumbsContainer.children;
        const cLength = children.length;
        let i;

        for (i = 0; i < cLength; i ++) {
            const thumb = children[i];

            if (thumb.classList.contains('show')) {
                let number = parseInt(thumb.dataset.number);

                for (i = 0; i < cLength; i++) {
                    const hideThumb = children[i];

                    hideThumb.classList.add('hide');
                    hideThumb.classList.remove('show');
                }

                if (number - 1 === 0) {
                    children[0].classList.add('show');
                    children[0].classList.remove('hide');
                } else if (number -1 < 0) {
                    children[cLength - 1].classList.add('show');
                    children[cLength - 1].classList.remove('hide')
                } else {
                    children[number - 1].classList.add('show');
                    children[number - 1].classList.remove('hide');
                }
            }
        }
    })
);
