const options = {
    rootMargin: '0px',
    threshold: 0.1
};

function fetchImage (url) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.onload = resolve;
        image.onerror = reject;
    });
}

function loadImage (image) {
    const src = image.dataset.src;
    fetchImage(src).then(() => {
        image.src = src;
    });
}

function handleIntersection (entries, observer) {
    entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
            loadImage(entry.target)
        }
    });
}

export const observer = new IntersectionObserver(handleIntersection, options);
