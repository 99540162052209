import { doc } from './utils';

export default function (msg) {
    const msgContainer = doc.createElement('article');

    msgContainer.className = 'display-message';
    msgContainer.innerHTML = msg;

    return msgContainer;
}
